import React, { useState } from 'react'
import { Button, Card, Col, Form, Row } from 'react-bootstrap'
import airsial from '../../images/airsial-logo.png'
import askari from '../../images/ASKARI-logo.jpg'
import allied from '../../images/Allied-Bank.png'
import bah from '../../images/Bank_al_habib_logo.svg.png'
import hbl from '../../images/HBL-logo-vector-01.jpg'
import ubl from '../../images/ubl-logo.png'
import meezan from '../../images/MEEZAN-logo.png'
import alflah from '../../images/Bank_alfalah_logo.png'
import toast from 'react-hot-toast';

const BankDetails = () => {
  const [copiedIndex, setCopiedIndex] = useState({ index: '', target: '' });

  const banks = [
    {
      name: 'Bank Al Habib Limited',
      title: 'FAIZAN AFZAL TARVEL & TOURS',
      accnumber: '01140081004303013',
      iban: 'PK40BAHL0114008100430301 ',
      logo: bah,
      bgcolor: 'black',
      nametextcolor: 'white',
      textcolor: 'black'

    },
    {
      name: 'Allied Bank',
      title: 'DHILLU & SAHI TARVEL & TOURS',
      accnumber: '05510010068954440015',
      iban: 'PK18ABPA0010068954440015',
      logo: allied,
      bgcolor: 'black',
      nametextcolor: 'white',
      textcolor: 'black'
    },
    {
      name: 'UBL Bank',
      title: 'FAIZAN AFZAL TARVELS AND TOURS',
      accnumber: '0666285796508',
      iban: 'PK29UNIL0109000285796508',
      logo: ubl,
      bgcolor: 'black',
      textcolor: 'black'
    },
    {
      name: 'Alflah Bank',
      title: 'DHILLU & SAHI TARVEL & TOURS',
      accnumber: '01121006952491',
      iban: 'PK11ALFH0112001006952491',
      logo: alflah,
      bgcolor: 'black',
      textcolor: 'black'
    },
    // {
    //   name: 'HBL Bank',
    //   title: 'FAIZAN AFZAL TARVEL & TOURS',
    //   accnumber: '04067901683203',
    //   iban: 'PK14HABB0004067901683203',
    //   logo: hbl,
    //   bgcolor: 'black',
    //   textcolor: 'black'
    // },
    {
      name: 'HBL Bank',
      title: 'DHILLU & SAHI TARVEL & TOURS',
      accnumber: '04067902091903',
      iban: 'PK14HABB0004067902091903',
      logo: hbl,
      bgcolor: 'black',
      textcolor: 'black'
    },
    {
      name: 'Askari Bank',
      title: 'DHILLU & SAHI TARVEL & TOURS',
      accnumber: '01570100580012',
      iban: 'PK76ASCM0001570100580012',
      logo: askari,
      bgcolor: 'black',
      textcolor: 'black'
    },
    {
      name: 'Meezan Bank',
      title: 'DASKA TARVEL & TOURS',
      accnumber: '38020107644655',
      iban: 'PK06MEZN0038020107644655',
      logo: meezan,
      bgcolor: 'black',
      textcolor: 'black'
    },
    // {
    //   name: '',
    //   title: '',
    //   accnumber: '',
    //   iban: '',
    //   logo: airsial,
    //   bgcolor: '#c1985b',
    //   textcolor: '#046C90'
    // },
  ]
  const [copyMessages, setCopyMessages] = useState(banks.map(() => 'Copy'));


  const handleCopyClick = (index, target) => {
    navigator.clipboard.writeText(banks[index][target]);
    toast.success(`Copied`)
    setCopiedIndex({ ...copiedIndex, index: index, target: target });
  };
  return (
    <>
      <section className='herobg' style={{ paddingTop: '77px' }}>
        <Col xxl={'9'} xl={'9'} lg={'10'} className={'mx-auto mt-5 col-11 text-center'}>
          <h1 className='whitetext pt-5'>Bank Accounts Details</h1>
          <p className='whitetext'>Here you can check out available bank details</p>
        </Col>
      </section>
      <section>
        <Col xxl={'9'} xl={'9'} lg={'10'} className={'mx-auto mt-5 col-11'}>
          <Row>
            {banks?.map((val, index) => {
              return (
                <>
                  <Col xxl={'6'} xl={'6'} lg={'6'}>
                    <div className="d-flex airbox" style={{ borderColor: val.bgcolor }} key={index}>
                      <div className='bankimg' style={{ borderColor: val.bgcolor }}>
                        <img className='img-fluid' alt={'icon'} src={val.logo} />
                      </div>
                      <div class="section-title">
                        <h2 className='mb-3' style={{ color: 'white', background: '#2d2d2d' }}>{val.name}</h2>
                        <p
                          className={`mb-0 cursor-pointer paragraphtext ${(copiedIndex.index === index && copiedIndex.target === 'title') ? 'copied' : ''
                            }`}
                          onClick={() => {
                            handleCopyClick(index, 'title')
                          }}
                        >
                          Title : <span className='' style={{ color: val.textcolor }}>{val.title}</span> <span className='mainorange ms-5'>{copiedIndex === index && 'Copied'}</span>
                        </p>
                        <p className={`mb-0 cursor-pointer paragraphtext ${(copiedIndex.index === index && copiedIndex.target === 'accnumber') ? 'copied' : ''
                          }`}
                          onClick={() => {
                            handleCopyClick(index, 'accnumber')
                          }}
                        >Acount Number : <span style={{ color: val.textcolor }}>{val.accnumber}</span></p>
                        {val.iban && <p className={`mb-3 cursor-pointer paragraphtext ${(copiedIndex.index === index && copiedIndex.target === 'iban') ? 'copied' : ''
                          }`}
                          onClick={() => {
                            handleCopyClick(index, 'iban')
                          }}
                        >IBAN Number : <span style={{ color: val.textcolor }}>{val.iban}</span></p>}

                        {val.aditionalText && (
                          <p className='mb-0 fontsize12 fontweight500'>
                            <b className='mainblue'>Note :</b> {val.aditionalText}
                          </p>
                        )}
                      </div>
                    </div>
                  </Col>
                </>
              );
            })}
          </Row>
        </Col>
      </section>
    </>
  )
}

export default BankDetails
